<template>
  <div class="l-wrapper">
    <q-scroll-area class="l-grow">
      <apexchart
        type="bar"
        height="350"
        :options="filteredCustomer.chartOptions"
        :series="filteredCustomer.series"
      ></apexchart>
    </q-scroll-area>
    <div>
      <q-btn
        unelevated
        class="no-border-radius full-width"
        color="primary"
        label="PENGATURAN GRAFIK"
        @click="showFilter = !showFilter"
      ></q-btn>
    </div>
    <q-dialog position="bottom" v-model="showFilter">
      <q-card style="min-width: 300px;">
        <q-card-section class="row justify-between">
          <a class="text-h6 text-bold">Filter:</a>
          <q-btn flat icon="close" v-close-popup></q-btn>
        </q-card-section>
        <q-card-section class="column q-gutter-sm">
          <q-select
            outlined
            class="bg-white"
            label="Tahun"
            @update:model-value="getmonth"
            :options="customer.data.optYear"
            v-model="options.selYear"
          ></q-select>
          <q-select
            outlined
            class="bg-white"
            label="Sales"
            emit-value
            map-options
            :options="sales.data.sales"
            v-model="options.selSales"
          ></q-select>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { useQuasar } from "quasar";
import {
  onMounted,
  onBeforeMount,
  ref,
  computed,
  reactive,
  onUnmounted,
} from "vue";
import VueApexCharts from "vue3-apexcharts";
import useManager from "./useManager";
import useSales from "../Sales/useSales";
import useCustomer from "../Customer/useCustomer";
import { onBeforeRouteLeave } from "vue-router";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  setup() {
    const { getCostumerGrowth, data, router, $q } = useManager();
    const sales = useSales();
    const customer = useCustomer();
    var months = [
      "N/A",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let options = reactive({
      selSales: "semua",

      date: {
        from: moment(),
        to: moment().add(1, "days"),
      },
      sel_time: "semua",

      selYear: null,
    });

    let getmonth = async (val) => {
      await customer.getOptMonth(val);
    };

    let filteredCustomer = computed(() => {
      let tempChart = JSON.parse(JSON.stringify(chart));
      let tempCustomer = JSON.parse(JSON.stringify(data.customer_growth));

      if (!options.selYear) {
        return tempChart;
      }

      if (options.selSales != "semua") {
        tempCustomer = tempCustomer.filter((val) => {
          return val.id_sales == options.selSales;
        });
      }

      for (let item of customer.data.optMonth) {
        let temp = tempCustomer.filter((val) => {
          return moment(val.date).format("M") == item;
        });

        tempChart.series[0].data.push(
          temp.filter((val) => {
            return val.type_after.toLowerCase() == "prospect";
          }).length
        );
        tempChart.series[1].data.push(
          temp.filter((val) => {
            return val.type_after.toLowerCase() == "new_1";
          }).length
        );
        tempChart.series[2].data.push(
          temp.filter((val) => {
            return val.type_after.toLowerCase() == "new_2";
          }).length
        );
        tempChart.series[3].data.push(
          temp.filter((val) => {
            return val.type_after.toLowerCase() == "new_3";
          }).length
        );
        tempChart.series[4].data.push(
          temp.filter((val) => {
            return val.type_after.toLowerCase() == "exist";
          }).length
        );
        tempChart.chartOptions.xaxis.categories.push(months[parseInt(item)]);
      }

      return tempChart;
    });

    let chart = reactive({
      series: [
        {
          name: "prospect",
          data: [],
        },
        {
          name: "new 1",
          data: [],
        },
        {
          name: "new 2",
          data: [],
        },
        {
          name: "new 3",
          data: [],
        },
        {
          name: "exist",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          id: "theChart",
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
    });

    onBeforeRouteLeave(() => {
      // window.ApexCharts.exec("theChart", "destroy", {});
    });
    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await customer.getOptYear();
        await sales.getOptSales();
        await getCostumerGrowth();

        let len = customer.data.optYear.length;
        options.selYear = customer.data.optYear[len - 1];
        getmonth(options.selYear);
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    return {
      options,
      data,
      chart,
      sales,
      customer,
      filteredCustomer,
      getmonth,
      showFilter: ref(true),
    };
  },
};
</script>
